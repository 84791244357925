import { GridRowId } from '@mui/x-data-grid-pro'
import { Holding, Summary } from 'Services/holdings'
import type { IAction, IActionWithToken, ITypedAction, ITypedActionWithToken } from 'Shared/types'
import { HoldingsActionTypes } from './holdings-constants'
import type { HoldingsFilterState, PinPayload } from './holdings-types'

const getHoldings = (token: string): IActionWithToken => ({
  type: HoldingsActionTypes.GET_HOLDINGS,
  token
})

const getHoldingsSuccess = (payload: Holding[]): ITypedAction<Holding[]> => ({
  type: HoldingsActionTypes.GET_HOLDINGS_SUCCESS,
  payload
})

const getHoldingsFailure = (): IAction => ({
  type: HoldingsActionTypes.GET_HOLDINGS_FAILURE,
})

const createHolding = (payload: Holding): ITypedAction<Holding> => ({
  type: HoldingsActionTypes.CREATE_HOLDING,
  payload
})

const archiveHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.ARCHIVE_HOLDING,
  payload: key,
  token
})

const deleteHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.DELETE_HOLDING,
  payload: key,
  token
})

const getSummary = (token: string): IActionWithToken => ({
  type: HoldingsActionTypes.GET_SUMMARY,
  token
})

const getSummarySuccess = (payload: Summary): ITypedAction<Summary> => ({
  type: HoldingsActionTypes.GET_SUMMARY_SUCCESS,
  payload
})

const getSummaryFailure = (): IAction => ({
  type: HoldingsActionTypes.GET_SUMMARY_FAILURE,
})

const togglePinHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.TOGGLE_PIN_HOLDING,
  payload: key,
  token
})

const pinHoldingSuccess = (payload: PinPayload): ITypedAction<PinPayload> => ({
  type: HoldingsActionTypes.PIN_HOLDING_SUCCESS,
  payload
})

const setSelectedHoldings = (payload: GridRowId[]): ITypedAction<GridRowId[]> => ({
  type: HoldingsActionTypes.SET_SELECTED_HOLDINGS,
  payload
})

const resetState = (): IAction => ({
  type: HoldingsActionTypes.RESET_STATE_HOLDINGS
})

const changeFilter = (filterState: HoldingsFilterState): ITypedAction<HoldingsFilterState> => ({
  type: HoldingsActionTypes.SET_HOLDINGS_FILTER_CHANGED,
  payload: filterState
})

export const HoldingActions = {
  getHoldings,
  getHoldingsSuccess,
  getHoldingsFailure,
  createHolding,
  archiveHolding,
  deleteHolding,
  getSummary,
  getSummarySuccess,
  getSummaryFailure,
  togglePinHolding,
  pinHoldingSuccess,
  setSelectedHoldings,
  resetState,
  changeFilter,
}
