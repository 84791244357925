import { Prediction } from 'Services/prediction'
import { Colors } from 'Shared/types'
import { PredictionState, PredictionType } from './prediction-types'
import type { PredictionCalculationData } from './prediction-types'

export const PredictionActionTypes = {
  LOAD_PREDICTION: 'LOAD_PREDICTION',
  REFRESH_PREDICTION: 'REFRESH_PREDICTION',
  LOAD_PREDICTION_SUCCESS: 'LOAD_PREDICTION_SUCCESS',
  LOAD_PREDICTION_FAILURE: 'LOAD_PREDICTION_FAILURE',
  UPDATE_PREDICTION_CALCULATION: 'UPDATE_PREDICTION_CALCULATION',
  UPDATE_PREDICTION_PROJECTION: 'UPDATE_PREDICTION_PROJECTION',
  RESET_STATE_PREDICTION: 'RESET_STATE_PREDICTION',
}

export const colorScheme = [
  Colors.Cornflower,
  Colors.Padua
]

export const TodayColor = '#3294FF'
export const RetireColor = '#F1B444'

export const YearsFrameItems = [ 5, 10, 30, 40, 50, 125 ]

export const EmptyPrediction: Prediction = {
  name: '',
  data: {
    savings: [],
    returns: [],
    info: []
  },
  metadata: {
    profitability: 0,
    retirePoint: {},
    retirePointReached: false
  },
}

export const DefaultCalculation: PredictionCalculationData = {
  currentBalance: 0,
  monthlySavings: 500,
  expectedSavingsGrowth: 5,
  profitabilityPercent: 7,
  calculateRetirement: false,
  predictionType: PredictionType.LiveOnPassiveIncome,
  currentAge: 25,
  monthlySpending: 500,
  liveOnCapitalLifeExpectancy: 95,
  expectedInflation: 3.27,
  retirementProfitability: 4,
}

export const DefaultPublicCalculation: PredictionCalculationData = {
  currentBalance: 10000,
  monthlySavings: 500,
  expectedSavingsGrowth: 5,
  profitabilityPercent: 7,
  calculateRetirement: true,
  predictionType: PredictionType.LiveOnPassiveIncome,
  currentAge: 25,
  monthlySpending: 500,
  liveOnCapitalLifeExpectancy: 95,
  expectedInflation: 3.27,
  retirementProfitability: 4,
}

export const InitialState: PredictionState = {
  isFirstTimeLoading: true,
  firstTimeLoadingCompleted: false,
  isLoading: false,
  prediction: EmptyPrediction,
  calculation: DefaultCalculation,
  yearsProjection: 30,
}

export const DefaultYearsToPredict = 50

export enum PredictionErrors {
  PredictionOverload = '2001'
}
