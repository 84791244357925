export interface ICurrencyService {
  getCurrencies(unitType: UnitType, term: string): Promise<string[]>
  getUnit(unitType: UnitType, symbol: string): Promise<MarketUnitInfo>
  getCurrencyExchangeRate(from: string, to: string): Promise<number>
}

export enum UnitType {
  RegularCurrency = 'RegularCurrency',
  CryptoCurrency = 'CryptoCurrency',
  Stocks = 'Stocks'
}

export type MarketUnitInfo = {
  symbol: string
  unitType: UnitType
  tradingCurrency: string
}
