import { container, Service } from 'Services/container'
import { ICurrencyService, UnitType } from 'Services/currency'

export const useExchangeRate = () => {
  const getExchangeRate = async (from: string, to: string) => {
    const currencyService = container.resolve<ICurrencyService>(Service.CurrencyService)

    return await currencyService.getCurrencyExchangeRate(from, to)
  }

  const getUnit = async (unitType: UnitType, symbol: string) => {
    const currencyService = container.resolve<ICurrencyService>(Service.CurrencyService)

    return await currencyService.getUnit(unitType, symbol)
  }

  return {
    getExchangeRate,
    getUnit,
  }
}
