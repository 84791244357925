import { PredictionRequest, Prediction } from 'Services/prediction'
import type { IAction, ITypedAction, ITypedActionWithToken, Nullable } from 'Shared/types'
import type { PredictionCalculationData } from './prediction-types'
import { PredictionActionTypes } from './prediction-constants'

const loadPrediction = (token: string, payload: PredictionRequest): ITypedActionWithToken<PredictionRequest> => ({
  type: PredictionActionTypes.LOAD_PREDICTION,
  token,
  payload
})

const refreshPrediction = (token: string, useProfileInitialBalance: Nullable<boolean>): ITypedActionWithToken<Nullable<boolean>> => ({
  type: PredictionActionTypes.REFRESH_PREDICTION,
  payload: useProfileInitialBalance,
  token,
})

const getPredictionSuccess = (payload: Prediction): ITypedAction<Prediction> => ({
  type: PredictionActionTypes.LOAD_PREDICTION_SUCCESS,
  payload
})

const getPredictionFailure = (): IAction => ({
  type: PredictionActionTypes.LOAD_PREDICTION_FAILURE,
})

const updatePredictionCalculation = (payload: PredictionCalculationData): ITypedAction<PredictionCalculationData> => ({
  type: PredictionActionTypes.UPDATE_PREDICTION_CALCULATION,
  payload
})

const updatePredictionProjection = (payload: number): ITypedAction<number> => ({
  type: PredictionActionTypes.UPDATE_PREDICTION_PROJECTION,
  payload
})

const resetState = (): IAction => ({
  type: PredictionActionTypes.RESET_STATE_PREDICTION
})

export const PredictionActions = {
  loadPrediction,
  refreshPrediction,
  getPredictionSuccess,
  getPredictionFailure,
  updatePredictionCalculation,
  updatePredictionProjection,
  resetState,
}
