import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import {
  IHoldingsService,
  BuySellItemRequest,
  TransferRequest,
  FixProfitLossRequest,
  DepositWithdrawRequest,
  EditHoldingRequest,
  CreateHoldingRequest,
  HoldingResponse,
} from './holdings-types'
import { Holding } from './holding'

export class HoldingsService extends BaseService implements IHoldingsService {
  public createHolding = (token: string, payload: CreateHoldingRequest) => axios
    .post('/holdings', { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public editHolding = (key: string, token: string, payload: EditHoldingRequest) => axios
    .put(`/holdings/${key}`, { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getHoldings = <T>(token: string) => axios
    .get<T>('/holdings/my', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
    .then((holdings: HoldingResponse[]) => holdings.map(holding => new Holding(holding)))

  public getHolding = <T>(key: string, token: string) => axios
    .get<T>(`/holdings/${key}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public archiveHolding = (key: string, token: string) => axios
    .patch(`/holdings/${key}/archive`, null, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public deleteHolding = (key: string, token: string) => axios
    .delete(`/holdings/${key}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getSummary = (token: string) => axios
    .get('/holdings/my/summary', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public pinHolding = (key: string, token: string) => axios
    .patch(`/holdings/${key}/pin`, null, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response)

  public depositWithdraw = (key: string, token: string, payload: DepositWithdrawRequest) => axios
    .patch(`/holdings/${key}/balance`, { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public fixProfitAndLoss = (key: string, token: string, payload: FixProfitLossRequest) => axios
    .patch(`/holdings/${key}/profit-loss`, { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public transfer = (token: string, payload: TransferRequest) => axios
    .patch('/holdings/transfer', { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public buySellItem = (key: string, token: string, payload: BuySellItemRequest) => axios
    .patch(`/holdings/${key}/buy-sell-item`, { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
