import React from 'react'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UnitType } from 'Services/currency'
import { CustomTickerAutocomplete } from 'Shared/components'
import { Nullable } from 'Shared/types'

interface Props {
  unitType: UnitType
  onTickerAdded: (ticker: string) => void
  tickers: string[]
}

export const AssetAllocationTickerSelector: React.FC<Props> = ({ unitType, onTickerAdded, tickers }) => {
  const { t } = useTranslation()
  const [ ticker, setTicker ] = React.useState<Nullable<string>>(null)
  const [ tickerError, setTickerError ] = React.useState<string | boolean>(false)

  const handleTickerChanged = (ticker: string | null) => {
    setTicker(ticker)

    if (ticker && tickers.includes(ticker)) {
      setTickerError(t('app.navbar.asset-allocation.newTicker-alreadyAdded'))
    } else {
      setTickerError(false)
    }
  }

  const handleAddNewTicker = () => {
    if (ticker && !tickers.includes(ticker)) {
      onTickerAdded(ticker)
      setTicker(null)
    }
  }

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <CustomTickerAutocomplete
        name="new-ticker"
        label={t('transactions.ticker.ticker')}
        value={ticker}
        onChange={handleTickerChanged}
        error={tickerError}
        unitType={unitType}
        includePopularOptions
      />

      <Button
        onClick={handleAddNewTicker}
        disabled={!ticker || !!tickerError}
        variant="outlined"
      >
        {t('common.actions.add')}
      </Button>
    </Stack>
  )
}
