import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'

export class PlaidService extends BaseService {
  public createLinkToken = (accessToken: string): Promise<string> => axios
    .post('/banking/plaid/link-token', {}, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public createAccessToken = (accessToken: string, linkToken: string) => axios
    .post('/banking/plaid/access-token', { publicToken: linkToken }, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)
}
