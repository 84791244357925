import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { History as HistoryType } from 'history'
import { historyReducer } from 'Shared/reducers/historyReducer'
import { analytics } from 'Analytics/analytics-reducer'
import { holdings } from 'Holdings/holdings-reducer'
import { transactions } from 'Transactions/transactions-reducer'
import { prediction } from 'Prediction/prediction-reducer'
import { advisorPortal } from 'AdvisorPortal/advisorPortal-reducer'
import { snackbarsReducer } from 'Snackbar/snackbar-reducer'
import { demo } from 'Demo/demo-reducer'
import { sharing } from 'Sharing/sharing-reducer'
import { tasks } from 'App/Tasks/Tasks-reducer'
import { assetAllocation } from 'App/AssetAllocation/assetAllocation-reducer'

export const rootReducer = (history: HistoryType<unknown>) => combineReducers({
  analytics,
  historyReducer,
  holdings,
  router: connectRouter(history),
  snackbars: snackbarsReducer,
  transactions,
  prediction,
  advisorPortal,
  demo,
  sharing,
  tasks,
  assetAllocation,
})
