import { Dictionary, LocalizedError } from 'Shared/types'

const errorMessage = (key: string, prop: any = null): LocalizedError => ({
  key: key,
  prop: prop
})

export const ErrorMessage = {
  Required: errorMessage('common.errorMessages.common-required'),
  RequiredCategory: errorMessage('common.errorMessages.category-required'),
  OnlyNumbers: errorMessage('common.errorMessages.number-typeMismatch'),
  TooShort: errorMessage('common.errorMessages.string-short'),
  InvalidUrl: errorMessage('common.errorMessages.url-invalid'),
  NotLessSymbolsThan: (length: number) => errorMessage('common.errorMessages.string-min', { length }),
  NotMoreSymbolsThan: (length: number) => errorMessage('common.errorMessages.string-max', { length }),
  NotLessThan: (min: number) => errorMessage('common.errorMessages.number-min', { min }),
  NotMoreThan: (max: number) => errorMessage('common.errorMessages.number-max', { max }),
  NotZero: errorMessage('common.errorMessages.number-nonZero'),
  Positive: errorMessage('common.errorMessages.number-positive'),
  ValidDate: errorMessage('common.errorMessages.date-typeMismatch'),
  InRangeDate: (from: Date, to: Date) => errorMessage('common.errorMessages.date-inRange', { range: { from, to } }),
  MinDate: (date: Date) => errorMessage('common.errorMessages.date-min', { date }),
  MaxDate: errorMessage('common.errorMessages.date-max'),
  ChooseValue: errorMessage('common.errorMessages.options-required'),
  ChooseHolding: errorMessage('common.errorMessages.holdingOptions-required'),
  PredictionValuesToBig: errorMessage('common.errorMessages.prediction-overload'),
  PredictionLifeExpectancy: errorMessage('common.errorMessages.lifeExpectancy-invalid'),
  TotalNotLessThan: (min: number) => errorMessage('common.errorMessages.total-min', { min }),
  MaximumValueForTicker: (ticker: string) => errorMessage('common.errorMessages.ticker-maxOperationValue', { ticker }),
  InsufficientFundsForTicker: (ticker: string) => errorMessage('common.errorMessages.synced-holding-positive', { ticker }),
  PositiveCashBalanceForSynced: errorMessage('common.errorMessages.positive-cashBalance'),
  PositiveTickerForSynced: (ticker: string) => errorMessage('common.errorMessages.positive-ticker', { ticker }),
  CommonBuySellFailure: (ticker: string) => errorMessage('holdings.manage.buySell-failure', { ticker }),
  CommonDepositWithdrawFailure: (holding: string) => errorMessage('holdings.manage.depositWithdraw-failure', { holding }),
  CommonCreateHoldingFailure: (holding: string) => errorMessage('holdings.manage.createHolding-failure', { holding }),
  CommonEditHoldingFailure: (holding: string) => errorMessage('holdings.manage.editHolding-failure', { holding }),
  CommonFixPnLFailure: (holding: string) => errorMessage('holdings.manage.fixProfitAndLoss-failure', { holding }),
  TransferForbiddenToSelf: errorMessage('holdings.manage.transfer-forbiddenToSelf'),
  BuyWhenOverLimitedFailure: errorMessage('holdings.manage.buySell-overLimitedMessage'),
  UsernameValidation: errorMessage('common.errorMessages.username-invalid'),
  MaxTagLength: (max: number) => errorMessage('common.errorMessages.tag-maxLength', { max }),
  MaxTagsLength: (max: number) => errorMessage('common.errorMessages.tags-maxLength', { max }),
  NotAllowedTag: errorMessage('common.errorMessages.tags-notAllowedName'),
  OperationFailure: errorMessage('common.errorMessages.operation-failure'),
  CommonTransferFailure: errorMessage('holdings.manage.transfer-failure'),
  CommonEditTransactionFailure: errorMessage('transactions.manage.editTransaction-failure'),
  DeleteTransactionFailure: errorMessage('transactions.manage.deleteTransaction-failure'),
}

export const ControlledServerError: Dictionary<LocalizedError> = {
  '3903': errorMessage('common.errorMessages.server-leadsToTransactionsChainInconsistency'),
  '4001': errorMessage('common.errorMessages.username-taken'),
  '4002': errorMessage('common.errorMessages.user-notFound'),
  '4003': errorMessage('common.errorMessages.username-notProvided'),
  '4004': errorMessage('common.errorMessages.username-usedInSharing'),
  '4005': errorMessage('common.errorMessages.subscription-active'),
  '4104': errorMessage('common.errorMessages.subscription-promoCodeNotFound'),
  '4105': errorMessage('common.errorMessages.subscription-usageLimitExceeded'),
  '4106': errorMessage('common.errorMessages.subscription-promoCodeAlreadyUsed'),
  '5001': errorMessage('common.errorMessages.sharing-onlyOneAllowed'),
  '5002': errorMessage('common.errorMessages.sharing-shareWithSelf'),
  '5003': errorMessage('common.errorMessages.sharing-shareWithNonAdvisor'),
  '99101': errorMessage('common.errorMessages.sharing-advisorTierLimits'),
  '0000': errorMessage('common.errorMessages.local-demoOperation'),
}
