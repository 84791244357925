import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { container, Service } from 'Services/container'
import { IHoldingsService, Summary, Holding } from 'Services/holdings'
import type { ITypedActionWithToken, IActionWithToken } from 'Shared/types'
import { snackbarActionCreators } from 'Snackbar'
import { HoldingActions } from './holdings-actions'
import { HoldingsActionTypes } from './holdings-constants'
import { HoldingSelectors } from './holdings-selectors'
import type { HoldingsState } from './holdings-types'
import { ArchiveHoldingSpecification } from './holdings-specifications'

export function* getHoldings({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Holding[] = yield call(holdingsService.getHoldings, token)
    yield put(HoldingActions.getHoldingsSuccess(response))
  } catch (error) {
    yield put(HoldingActions.getHoldingsFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.table.loading-failure'))
  }
}

export function* getSummary({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Summary = yield call(holdingsService.getSummary, token)
    yield put(HoldingActions.getSummarySuccess(response))
  } catch (error) {
    yield put(HoldingActions.getSummaryFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.summary.loading-failure'))
  }
}

function* archiveHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingState: HoldingsState = yield select(HoldingSelectors.getHoldingsStateSelector)
    const holdingToArchive: Holding | null = HoldingSelectors.getHoldingByKeySelector(holdingState)(payload)

    if (!holdingToArchive) { return }

    const specification = new ArchiveHoldingSpecification(holdingToArchive)
    const specificationResult = specification.verify()

    if (!specificationResult.isValid) {
      yield put(snackbarActionCreators.enqueueWarningSnackbar(specificationResult.errorKey))
      return
    }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.archiveHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.archive-success', { holding: holdingToArchive.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.archive-failure'))
  }
}

function* deleteHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingState: HoldingsState = yield select(HoldingSelectors.getHoldingsStateSelector)
    const holdingToDelete: Holding | null = HoldingSelectors.getHoldingByKeySelector(holdingState)(payload)

    if (!holdingToDelete) { return }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.deleteHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.delete-success', { holding: holdingToDelete.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.delete-failure'))
  }
}

function* pinHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.pinHolding, payload, token)
    const pinDate = new Date().toISOString()
    const data = { key: payload, pinDate }
    yield put(HoldingActions.pinHoldingSuccess(data))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.pin-failure'))
  }
}

export function* holdingsSaga() {
  yield all([
    takeEvery(HoldingsActionTypes.GET_HOLDINGS, getHoldings),
    takeEvery(HoldingsActionTypes.GET_SUMMARY, getSummary),
    takeEvery(HoldingsActionTypes.ARCHIVE_HOLDING, archiveHolding),
    takeEvery(HoldingsActionTypes.DELETE_HOLDING, deleteHolding),
    takeEvery(HoldingsActionTypes.TOGGLE_PIN_HOLDING, pinHolding),
  ])
}
