import { createSelector } from 'reselect'
import { chain } from 'lodash'

import { Holding } from 'Services/holdings'
import type { StoreState } from 'Shared/types'
import type { HoldingsState } from './holdings-types'

const holdingsStoreSelector = (state: StoreState): HoldingsState => state.holdings
const getHoldingsStateSelector = (state: HoldingsState): Holding[] => state.holdings
const getHoldingsSelector = createSelector(holdingsStoreSelector, (state: HoldingsState) => state.holdings)

const getSelectedHoldingsSelector = createSelector(
  holdingsStoreSelector,
  state => {
    const selectedHoldingKeys = state.selectedHoldings

    if (state.holdings.length === 0 || state.selectedHoldings.length === 0) {
      return []
    }

    return state.holdings.filter(holding => selectedHoldingKeys.includes(holding.key))
  }
)

const getIsLoadingSelector = createSelector(holdingsStoreSelector, state => state.isLoading)
const getInitialLoadingState = createSelector(holdingsStoreSelector, state => state.isInitialLoadingCompleted)
const getIsSummaryLoadingSelector = createSelector(holdingsStoreSelector, state => state.isSummaryLoading)
const getSummarySelector = createSelector(holdingsStoreSelector, state => state.summary)

// TODO: remove
const getHoldingByKeySelector = createSelector(
  getHoldingsStateSelector,
  holdings => (holdingKey?: string): Holding | null => holdings.find(holding => holding.key === holdingKey) || null
)

const holdingSelector = createSelector(
  (state: StoreState) => state.holdings.holdings,
  (state: StoreState, holdingKey: string | null) => holdingKey,
  (holdings, holdingKey) => holdings.find(holding => holding.key === holdingKey) || null
)

const getCategories = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.category)
    .compact()
    .uniq()
    .value())

const getCompanies = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.asset.company)
    .compact()
    .uniq()
    .value())

const getCountries = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.metadata.country)
    .compact()
    .uniq()
    .value())

const getCurrencies = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.currency)
    .compact()
    .uniq()
    .value())

const getTags = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .flatMap(holding => holding.tags)
    .compact()
    .uniq()
    .value())

const hasCountries = createSelector(holdingsStoreSelector, state => state.holdings.some(holding => holding.metadata.country))
const getFilterState = createSelector(holdingsStoreSelector, state => state.holdingsFilter)

// Helper methods
// TODO: remove helper methods
const getHoldingByKey = (holdings: Holding[], key: string): Holding | null =>
  holdings.find((holding: Holding) => holding.key === key) ?? null

export const HoldingSelectors = {
  getHoldingsSelector,
  getHoldingsStateSelector,
  getSelectedHoldingsSelector,
  getIsLoadingSelector,
  getInitialLoadingState,
  getIsSummaryLoadingSelector,
  getSummarySelector,
  getHoldingByKeySelector,
  getCategories,
  getCompanies,
  getCountries,
  getCurrencies,
  getTags,
  hasCountries,
  getHoldingByKey,
  getFilterState,
  holdingSelector,
}
