import { remove, set, sortBy } from 'lodash'

import { Holding, Summary } from 'Services/holdings'
import type { ITypedAction } from 'Shared/types'
import { HoldingsActionTypes } from './holdings-constants'
import type { HoldingsFilterState, HoldingsState, PinPayload } from './holdings-types'

export const updateHoldingsWithPinned = (initialHoldings: Holding[], data: PinPayload): Holding[] => {
  const holdings = [ ...initialHoldings ]
  const pinnedHolding = holdings.find((holding: Holding) => holding.key === data.key)
  if (!pinnedHolding) { return holdings }

  const pinDateTime = !pinnedHolding.pinDateTime ? data.pinDate : null
  set(pinnedHolding, 'pinDateTime', pinDateTime)
  remove(holdings, (holding: Holding) => holding.key === data.key)

  const updatedHoldings = [ ...holdings, pinnedHolding ]

  return sortBy(
    updatedHoldings,
    [ 'pinDateTime', 'category', 'createdOn', 'asset.name' ]
  )
}

export const initialState: HoldingsState = {
  isLoading: true,
  isSummaryLoading: true,
  isInitialLoadingCompleted: false,
  holdings: [],
  selectedHoldings: [],
  summary: null,
  holdingsFilter: {
    selectedTags: [],
    selectedCategories: [],
    selectedClasses: [],
    selectedCountries: [],
    searchQuery: ''
  },
}

export const holdings = (
  state: HoldingsState = initialState,
  action: ITypedAction<any>
): HoldingsState => {
  switch(action.type) {

    case HoldingsActionTypes.SET_HOLDINGS_PAGE_LOADING: {
      return {
        ...state,
        isLoading: true,
        isSummaryLoading: true,
      }
    }

    case HoldingsActionTypes.GET_HOLDINGS_SUCCESS: {
      return {
        ...state,
        holdings: action.payload as Holding[],
        isLoading: false,
        isInitialLoadingCompleted: true,
      }
    }

    case HoldingsActionTypes.GET_HOLDINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isInitialLoadingCompleted: true,
      }
    }

    case HoldingsActionTypes.GET_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload as Summary,
        isSummaryLoading: false,
      }
    }

    case HoldingsActionTypes.GET_SUMMARY_FAILURE: {
      return {
        ...state,
        isSummaryLoading: false,
      }
    }

    case HoldingsActionTypes.PIN_HOLDING_SUCCESS: {
      return {
        ...state,
        holdings: updateHoldingsWithPinned(state.holdings, action.payload as PinPayload)
      }
    }

    case HoldingsActionTypes.SET_SELECTED_HOLDINGS: {
      return {
        ...state,
        selectedHoldings: action.payload as string[]
      }
    }

    case HoldingsActionTypes.RESET_STATE_HOLDINGS: {
      return initialState
    }

    case HoldingsActionTypes.SET_HOLDINGS_FILTER_CHANGED: {
      const holdingsFilter = action.payload as HoldingsFilterState

      return {
        ...state,
        holdingsFilter
      }
    }

    default:
      return state
  }
}
