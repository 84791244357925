import React from 'react'
import { useField } from 'formik'

import { AutocompleteOption, CustomTickerAutocomplete } from 'Shared/components/NonForm'
import { Nullable } from 'Shared/types'
import { UnitType } from 'Services/currency'

interface Props {
  name: string
  label: string
  unitType: UnitType
  initialOptions?: AutocompleteOption[]
  includePopularOptions?: boolean
  groupOrder?: string[]
  required?: boolean
  disabled?: boolean
  customOnChange?: (value: Nullable<string>) => void
  extraProps?: any
}

export const FormikTickerAutocomplete: React.FC<Props> = props => {
  const [ field, meta, helpers ] = useField<string | null>(props.name)

  const setTouched = () => {
    helpers.setTouched(true)
  }

  const handleOnChange = React.useCallback((value: Nullable<string>) => {
    helpers.setValue(value)

    if (props.customOnChange) {
      props.customOnChange(value)
    }
  }, [ props.customOnChange ])

  return (
    <CustomTickerAutocomplete
      {...props}
      {...field}
      error={meta.touched && meta.error}
      onChange={handleOnChange}
      onBlur={setTouched}
    />
  )
}
