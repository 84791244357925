import React, { PropsWithChildren } from 'react'
import { Stack, Box } from '@mui/material'

import { TextLarge } from 'Shared/components/Typography'

interface Props {
  title: string
  controlPanel?: JSX.Element
}

export const ViewDialogSection: React.FC<PropsWithChildren<Props>> = ({ title, controlPanel, children }) => (
  <Stack sx={{ height: '100%' }} spacing={1}>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TextLarge fontWeight={700}>{title}</TextLarge>
      {controlPanel}
    </Stack>

    {children}
  </Stack>
)
