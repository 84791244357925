import { Alert } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage, languageService } from 'config/i18n-types'
import { UnitType } from 'Services/currency'
import { FormikTickerAutocomplete, Select, StyledForm, SubmitButton, TextField } from 'Shared/components'
import { FormFields, FormState } from './form-fields'

const PopularCurrencyOptions = [
  { label: 'UAH', value: 'UAH' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
]

export const UserSettingsForm: React.FC = () => {
  const { t } = useTranslation()
  const formProps = useFormikContext<FormState>()

  const languageOptions = React.useMemo(
    () => Object
      .keys(languageService.translations)
      .map(key => ({
        text: t(languageService.getTranslation(key as SupportedLanguage)),
        value: key
      })),
    [])

  return (
    <StyledForm autoComplete="off" name="user-settings" id="user-settings">
      <Alert severity="info">{t('app.navbar.settings.username-warning')}</Alert>
      <TextField
        name={FormFields.username}
        label={t('app.navbar.settings.username')}
        placeholder={t('app.navbar.settings.username-placeholder')}
      />

      <FormikTickerAutocomplete
        name={FormFields.baseCurrency}
        label={t('app.navbar.settings.currency')}
        unitType={UnitType.RegularCurrency}
        initialOptions={PopularCurrencyOptions}
        disabled={formProps.isSubmitting}
        required
      />

      <Select
        name={FormFields.language}
        label={t('app.navbar.settings.language')}
        options={languageOptions}
        disabled={formProps.isSubmitting}
      />

      <SubmitButton formProps={formProps} />
    </StyledForm>
  )
}
