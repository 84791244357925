import type { AxiosResponse } from 'axios'

import { exchangeRateInstance } from 'config/axios'
import { BaseService } from 'Shared/api'
import { ICurrencyService, UnitType, MarketUnitInfo } from './currency-types'

export class CurrencyService extends BaseService implements ICurrencyService {
  public getCurrencies = (unitType: UnitType, term: string) => exchangeRateInstance
    .get<string[]>(`/currencies/${unitType}?term=${term}`)
    .then((response: AxiosResponse) => response?.data)

  public getUnit = (unitType: UnitType, symbol: string) => exchangeRateInstance
    .get<MarketUnitInfo>(`/units/${unitType}/${symbol}`)
    .then((response: AxiosResponse) => response?.data)

  public getCurrencyExchangeRate = (from: string, to: string) => exchangeRateInstance
    .get(`/exchange-rates/${from}/${to}`)
    .then((response: AxiosResponse) => response?.data)
}
