import { LOCATION_CHANGE } from 'connected-react-router'

import type { HistoryState, LocationChangeAction } from 'Shared/types'

const initialState: HistoryState = {
  paths: [],
}

export const historyReducer = (state: HistoryState = initialState, action: LocationChangeAction): HistoryState => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const path = action.payload.location.pathname

      return {
        ...state,
        paths: [ ...state.paths, path ]
      }
    }
    default:
      return state
  }
}
