import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { PredictionRequest, IPredictionService } from './prediction-types'

export class PredictionService extends BaseService implements IPredictionService {
  getPrediction<T>(token: string, payload: PredictionRequest) {
    const queryString = [
      `monthlySavings=${payload.monthlySavings}`,
      `profitabilityPercent=${payload.profitabilityPercent}`,
      `yearsToForecast=${payload.yearsToForecast}`,
      `savingsGrowthPercent=${payload.savingsGrowthPercent}`
    ]

    if (payload.startingBalance !== null) {
      queryString.push(`startingBalance=${payload.startingBalance}`)
    }

    if (payload.liveOnPassiveIncome) {
      queryString.push(`passiveIncome.monthlySpending=${payload.liveOnPassiveIncome.monthlySpending}`)
      queryString.push(`passiveIncome.retirementProfitability=${payload.liveOnPassiveIncome.retirementProfitability}`)
      queryString.push(`passiveIncome.inflationPercent=${payload.liveOnPassiveIncome.inflationPercent}`)
      queryString.push(`passiveIncome.currentAge=${payload.liveOnPassiveIncome.currentAge}`)
    }

    if (payload.liveOnCapital) {
      queryString.push(`liveOnCapital.monthlySpending=${payload.liveOnCapital.monthlySpending}`)
      queryString.push(`liveOnCapital.retirementProfitability=${payload.liveOnCapital.retirementProfitability}`)
      queryString.push(`liveOnCapital.inflationPercent=${payload.liveOnCapital.inflationPercent}`)
      queryString.push(`liveOnCapital.currentAge=${payload.liveOnCapital.currentAge}`)
      queryString.push(`liveOnCapital.lifeExpectancy=${payload.liveOnCapital.lifeExpectancy}`)
    }

    return axios
      .get<T>('/forecast?' + queryString.join('&'), { headers: { ...super.buildAuthHeader(token) } })
      .then((response: AxiosResponse) => response?.data)
  }
}
